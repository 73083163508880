import React from "react"
import logo from "../twc-logo.jpg"

export default () => {
  return (
    <>
      <div className="headWrapper">
        <img src={logo} className="logo" />
        <h1>
          Mutual <span>Aid</span>
        </h1>
      </div>
      <h3>Sparking the Collective Imagination</h3>
      <p>
        This project’s goal is to create relationships of accountable mutual aid
        in San Diego. Guided by our vision for an inclusive &amp; equitable tech
        industry, TWC organizes to build worker power through rank & file
        self-organization and education.
      </p>
      <h3>When Mutual Aid Matters</h3>

      <ul>
        <li>
          Is there a new project where additional tech-related research or tech
          consultation could make a difference?
        </li>

        <li>
          Does your org have an immediate outcome you’re working toward that
          could use additional momentum or awareness?
        </li>

        <li>
          Can tech workers help your community claim more control over
          technology?
        </li>
      </ul>
      <h3>Examples of projects</h3>

      <ul>
        <li>
          Coders help maintain and improve a website, Turkopticon, that online
          workers use to share information about potential and past employers
        </li>

        <li>
          United Taxi Workers worked with a software engineer to clean and
          crunch government-provided data to make arguments in support of their
          advocacy
        </li>

        <li>
          Bay Area tech workers built a website for gig workers to streamline
          the process of filing wage claims post AB-5
        </li>

        <li>
          Helping San Diego TRUST Coalition: assessing what smart streetlight
          surveillance could do technically and contractually, assessing claims
          made by City of San Diego about the tech; helping compose public
          records requests
        </li>
      </ul>
      <p>
        These are just a few examples, and do not represent all areas where
        mutual aid could be accomplished.{" "}
        <strong>
          Ultimately we are interested in learning about your organization’s
          goals and how we could work together for positive change.{" "}
        </strong>
        For a full archive of tech worker organizing efforts see{" "}
        <a href="https://collectiveaction.tech">collectiveaction.tech</a>
      </p>
      <h3>What is Mutual Aid?</h3>

      <ul>
        <li>Talking with communities interested in collaboration</li>

        <li>Connecting accountable, technically skilled volunteers</li>

        <li>Working together to mutually assist, inform, and empower</li>

        <li>
          Recognizing we all have resources and knowledge to share and we are
          stronger together
        </li>
      </ul>
      <h3>What Does Collaboration Look Like?</h3>

      <p>
        Driving collective volunteer work through a meaningful dialog. This
        collaboration can take many forms. Here are some examples:
      </p>
      <ul>
        <li>Website Design &amp; Development</li>

        <li>Graphic Design</li>

        <li>Data &amp; Technology Consultation</li>

        <li>App Development</li>

        <li>Digital Communication</li>

        <li>Community tech education</li>

        <li>
          Don't hesitate to ask about anything else you need labor time. If it
          aligns with our goals to build worker and community power, we will try
          to help!
        </li>
      </ul>
      <h3>Next Steps</h3>

      <p>
        Reach out to us at via email at{" "}
        <a href="mailto:hello@techworkersco.org">hello@techworkersco.org</a> or
        for more information, check out our website{" "}
        <a href="https://techworkerscoalition.org/">techworkerscoalition.org</a>
        .
      </p>
      <p>
        Text us at <a href="sms:6195661559">619.566.1559</a>
      </p>
    </>
  )
}
